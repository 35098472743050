import request from '@/utils/request'

// 创建主体
export function createEntity (params) {
  return request.post('/api/createEntity', params)
}

// 修改主体
export function editEntity (params) {
  return request.post('/api/editEntity', params)
}

// 主体列表
export function listEntity (params) {
  return request.post('/api/listEntity', params)
}

// 删除禁用主体
export function statusEntity (params) {
  return request.post('/api/statusEntity', params)
}

// 审核主体
export function auditEntity (params) {
  return request.post('/api/auditEntity', params)
}

// 导出主体
export function exportEntity (params) {
  return request.post('/api/exportEntity', params)
}

// 主体操作记录
export function logEntity (params) {
  return request.post('/api/logEntity', params)
}

// 主体详情
export function infoEntity (params) {
  return request.post('/api/infoEntity', params)
}

//发布主体
export function displayEntity (params) {
  return request.post('/api/displayEntity', params)
}