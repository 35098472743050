<template>
  <div class="createEntiy">
    <el-form
      ref="createForm"
      :model="createForm"
      label-width="306px"
      size="small"
      :rules="rules"
    >
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="企业类型：" prop="sign_type">
            <el-select
              style="width: 100%; min-width: 180px"
              v-model="createForm.sign_type"
              :disabled="isEdit"
            >
              <el-option
                v-for="item in signTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="合同类型：" prop="contract_type">
            <el-radio
              v-model="createForm.contract_type"
              :label="0"
              :disabled="isEdit"
              >纸质合同</el-radio
            >
          </el-form-item>
        </el-col>
      </el-row>
      <div v-if="createForm.sign_type != 'micro'">
        <el-divider content-position="left">
          <h2>企业信息</h2>
        </el-divider>
        <div>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="公司名称：" prop="entity_name">
                <el-input
                  v-model="createForm.entity_name"
                  maxlength="50"
                  style="width: 100%; min-width: 180px"
                  clearable
                  placeholder="请输入"
                  :disabled="isEdit"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="公司简称：" prop="entity_simple_name">
                <el-input
                  v-model="createForm.entity_simple_name"
                  maxlength="10"
                  style="width: 100%; min-width: 180px"
                  clearable
                  placeholder="请输入"
                  :disabled="isEdit"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="客服电话：" prop="service_phone">
                <el-input
                  v-model="createForm.service_phone"
                  maxlength="11"
                  style="width: 100%; min-width: 180px"
                  clearable
                  placeholder="请输入"
                  :disabled="isEdit"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="统一社会信用代码：" prop="entity_number">
                <el-input
                  v-model="createForm.entity_number"
                  maxlength="20"
                  style="width: 100%; min-width: 180px"
                  clearable
                  placeholder="请输入"
                  :disabled="isEdit"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="公司营业执照照片：">
                <img
                  v-if="isEdit"
                  :src="createForm.licence_photo_path_url"
                  @click="previewBigImg(createForm.licence_photo_path_url)"
                  class="upload_img"
                />
                <uploadImage
                  v-if="!isEdit"
                  :limit="1"
                  @getImgUrl="getLicencePhotoPath"
                  :value="createForm.licence_photo_path_url"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item
                label="开户许可证编号："
                prop="entity_account_lience_num"
              >
                <el-input
                  v-model="createForm.entity_account_lience_num"
                  maxlength="20"
                  style="width: 100%; min-width: 180px"
                  clearable
                  placeholder="请输入"
                  :disabled="isEdit"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="开户许可证照片：">
                <img
                  v-if="isEdit"
                  :src="createForm.entity_account_lience_photo_url"
                  @click="
                    previewBigImg(createForm.entity_account_lience_photo_url)
                  "
                  class="upload_img"
                />
                <uploadImage
                  v-if="!isEdit"
                  :limit="1"
                  @getImgUrl="getEntityAccountLiencePhoto"
                  :value="createForm.entity_account_lience_photo_url"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <div class="flex">
                <el-form-item
                  label="实际经营地："
                  prop="business_address_province_id"
                >
                  <el-select
                    placeholder="选择省份"
                    style="min-width: 180px"
                    v-model="createForm.business_address_province_id"
                    clearable
                    @change="onProvinceChange"
                    :disabled="isEdit"
                  >
                    <el-option
                      v-for="item in provinceList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label-width="0px"
                  prop="business_address_city_id"
                  class="ml_20"
                >
                  <el-select
                    placeholder="选择城市"
                    style="min-width: 180px"
                    v-model="createForm.business_address_city_id"
                    clearable
                    :disabled="isEdit"
                    @change="onCityChange"
                  >
                    <el-option
                      v-for="item in cityList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label-width="0px"
                  prop="business_address_district_id"
                  class="ml_20"
                >
                  <el-select
                    placeholder="选择区/县"
                    style="min-width: 180px"
                    v-model="createForm.business_address_district_id"
                    clearable
                    :disabled="isEdit"
                  >
                    <el-option
                      v-for="item in countyList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label-width="0px"
                  prop="business_address"
                  class="ml_20"
                >
                  <el-input
                    placeholder="请输入详细地址"
                    v-model="createForm.business_address"
                    maxlength="50"
                    style="min-width: 180px"
                    clearable
                    :disabled="isEdit"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
        </div>
        <el-divider content-position="left">
          <h2>法人&联系人</h2>
        </el-divider>
        <div>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="法人姓名：" prop="legal_user_name">
                <el-input
                  v-model="createForm.legal_user_name"
                  maxlength="6"
                  style="width: 100%; min-width: 180px"
                  clearable
                  placeholder="请输入"
                  :disabled="isEdit"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item
                label="法人证件类型："
                prop="legal_user_licence_type"
              >
                <el-select
                  style="width: 100%; min-width: 180px"
                  v-model="createForm.legal_user_licence_type"
                  clearable
                  :disabled="isEdit"
                >
                  <el-option
                    v-for="item in credentialsTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="法人证件号码：" prop="legal_user_licence_no">
                <el-input
                  v-model="createForm.legal_user_licence_no"
                  maxlength="50"
                  style="width: 100%; min-width: 180px"
                  clearable
                  placeholder="请输入"
                  :disabled="isEdit"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="法人身份证照片正面：">
                <img
                  :src="createForm.legal_user_licence_photo_front_url"
                  @click="
                    previewBigImg(createForm.legal_user_licence_photo_front_url)
                  "
                  class="upload_img"
                  v-if="isEdit"
                />
                <uploadImage
                  v-if="!isEdit"
                  :limit="1"
                  @getImgUrl="getLegalUserLicencePhotoFront"
                  :value="createForm.legal_user_licence_photo_front_url"
                >
                </uploadImage>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="法人身份证照片反面：">
                <img
                  :src="createForm.legal_user_licence_photo_back_url"
                  @click="
                    previewBigImg(createForm.legal_user_licence_photo_back_url)
                  "
                  class="upload_img"
                  v-if="isEdit"
                />
                <uploadImage
                  v-if="!isEdit"
                  :limit="1"
                  @getImgUrl="getLegalUserLicencePhotoBack"
                  :value="createForm.legal_user_licence_photo_back_url"
                >
                </uploadImage>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="联系人姓名：" prop="contact_user_name">
                <el-input
                  v-model="createForm.contact_user_name"
                  maxlength="6"
                  style="width: 100%; min-width: 180px"
                  clearable
                  placeholder="请输入"
                  :disabled="isEdit"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="联系人身份证号：" prop="contact_user_idnum">
                <el-input
                  v-model="createForm.contact_user_idnum"
                  maxlength="18"
                  style="width: 100%; min-width: 180px"
                  placeholder="请输入"
                  :disabled="isEdit"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="联系人手机号：" prop="contact_user_phone">
                <el-input
                  v-model="createForm.contact_user_phone"
                  maxlength="11"
                  style="width: 100%; min-width: 180px"
                  clearable
                  :disabled="isEdit"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="联系人邮箱：" prop="contact_user_email">
                <el-input
                  v-model="createForm.contact_user_email"
                  style="width: 100%; min-width: 180px"
                  :disabled="isEdit"
                  clearable
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <el-divider content-position="left">
          <h2>收款账户</h2>
        </el-divider>
        <div>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="账户类型：" prop="entity_account_type">
                <el-select
                  style="width: 100%; min-width: 180px"
                  v-model="createForm.entity_account_type"
                  clearable
                  :disabled="isEdit && !editBack"
                >
                  <el-option
                    v-for="item in accountTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="开户行：" prop="bank_id">
                <el-select
                  style="width: 100%; min-width: 180px"
                  filterable
                  remote
                  :remote-method="remoteMethod"
                  v-model="createForm.bank_id"
                  clearable
                  :disabled="isEdit && !editBack"
                >
                  <el-option
                    v-for="item in bankList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="账号：" prop="entity_account">
                <el-input
                  v-model="createForm.entity_account"
                  style="width: 100%; min-width: 180px"
                  clearable
                  placeholder="请输入"
                  :disabled="isEdit && !editBack"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div
            class="edit-button"
            v-if="entity_id && createForm.sign_status > 1"
          >
            <el-button type="primary" v-if="!editBack" @click="editInfo('bank')"
              >编辑</el-button
            >
            <el-button type="primary" v-else @click="getEditEntity('bank')"
              >保存</el-button
            >
          </div>
        </div>
      </div>
      <div v-else>
        <el-divider content-position="left">
          <h2>商户信息</h2>
        </el-divider>
        <div>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="商户名称：" prop="entity_name">
                <el-input
                  v-model="createForm.entity_name"
                  maxlength="50"
                  style="width: 100%; min-width: 180px"
                  clearable
                  placeholder="与商户证件名称一致"
                  :disabled="isEdit"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="商户简称：" prop="entity_simple_name">
                <el-input
                  v-model="createForm.entity_simple_name"
                  maxlength="10"
                  style="width: 100%; min-width: 180px"
                  clearable
                  placeholder="请输入"
                  :disabled="isEdit"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <el-divider content-position="left">
          <h2>法人信息</h2>
        </el-divider>
        <div>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item
                label="法人证件类型："
                prop="legal_user_licence_type"
              >
                <el-select
                  style="width: 100%; min-width: 180px"
                  v-model="createForm.legal_user_licence_type"
                  clearable
                  :disabled="isEdit"
                >
                  <el-option
                    v-for="item in credentialsTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="法人证件号码：" prop="legal_user_licence_no">
                <el-input
                  v-model="createForm.legal_user_licence_no"
                  maxlength="50"
                  style="width: 100%; min-width: 180px"
                  clearable
                  placeholder="请输入"
                  :disabled="isEdit"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="法人身份证照片正面：">
                <img
                  :src="createForm.legal_user_licence_photo_front_url"
                  @click="
                    previewBigImg(createForm.legal_user_licence_photo_front_url)
                  "
                  class="upload_img"
                  v-if="isEdit"
                />
                <uploadImage
                  v-if="!isEdit"
                  :limit="1"
                  @getImgUrl="getLegalUserLicencePhotoFront"
                  :value="createForm.legal_user_licence_photo_front_url"
                >
                </uploadImage>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="法人身份证照片反面：">
                <img
                  :src="createForm.legal_user_licence_photo_back_url"
                  @click="
                    previewBigImg(createForm.legal_user_licence_photo_back_url)
                  "
                  class="upload_img"
                  v-if="isEdit"
                />
                <uploadImage
                  v-if="!isEdit"
                  :limit="1"
                  @getImgUrl="getLegalUserLicencePhotoBack"
                  :value="createForm.legal_user_licence_photo_back_url"
                >
                </uploadImage>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="法人手机号：" prop="contact_user_phone">
                <el-input
                  v-model="createForm.contact_user_phone"
                  maxlength="11"
                  style="width: 100%; min-width: 180px"
                  clearable
                  :disabled="isEdit"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <el-divider content-position="left">
          <h2>经营地址</h2>
        </el-divider>
        <div>
          <el-row :gutter="20">
            <el-col :span="24">
              <div class="flex">
                <el-form-item
                  label="实际经营地："
                  prop="business_address_province_id"
                >
                  <el-select
                    placeholder="选择省份"
                    style="min-width: 180px"
                    v-model="createForm.business_address_province_id"
                    clearable
                    @change="onProvinceChange"
                    :disabled="isEdit"
                  >
                    <el-option
                      v-for="item in provinceList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label-width="0px"
                  prop="business_address_city_id"
                  class="ml_20"
                >
                  <el-select
                    placeholder="选择城市"
                    style="min-width: 180px"
                    v-model="createForm.business_address_city_id"
                    clearable
                    :disabled="isEdit"
                    @change="onCityChange"
                  >
                    <el-option
                      v-for="item in cityList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label-width="0px"
                  prop="business_address_district_id"
                  class="ml_20"
                >
                  <el-select
                    placeholder="选择区/县"
                    style="min-width: 180px"
                    v-model="createForm.business_address_district_id"
                    clearable
                    :disabled="isEdit"
                  >
                    <el-option
                      v-for="item in countyList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label-width="0px"
                  prop="business_address"
                  class="ml_20"
                >
                  <el-input
                    placeholder="请输入详细地址"
                    v-model="createForm.business_address"
                    maxlength="50"
                    style="min-width: 180px"
                    clearable
                    :disabled="isEdit"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
        </div>
        <el-divider content-position="left">
          <h2>收款账户</h2>
        </el-divider>
        <div>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="账户类型：" prop="entity_account_type">
                <el-select
                  style="width: 100%; min-width: 180px"
                  v-model="createForm.entity_account_type"
                  clearable
                  :disabled="isEdit && !editBack"
                >
                  <el-option
                    v-for="item in personageAccountTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="开户行：" prop="bank_id">
                <el-select
                  style="width: 100%; min-width: 180px"
                  filterable
                  remote
                  :remote-method="remoteMethod"
                  v-model="createForm.bank_id"
                  clearable
                  :disabled="isEdit && !editBack"
                >
                  <el-option
                    v-for="item in bankList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="账号：" prop="entity_account">
                <el-input
                  v-model="createForm.entity_account"
                  style="width: 100%; min-width: 180px"
                  clearable
                  placeholder="请输入"
                  :disabled="isEdit && !editBack"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div
            class="edit-button"
            v-if="entity_id && createForm.sign_status > 1"
          >
            <el-button type="primary" v-if="!editBack" @click="editInfo('bank')"
              >编辑</el-button
            >
            <el-button type="primary" v-else @click="getEditEntity('bank')"
              >保存</el-button
            >
          </div>
        </div>
      </div>
      <el-divider content-position="left">
        <h2>签约信息</h2>
      </el-divider>
      <div>
        <el-form-item label="管理费率：" prop="entity_fee_list">
          <div
            class="rate-list"
            v-for="(item, index) in createForm.entity_fee_list"
            :key="index"
          >
            <el-input
              v-model="item.stage_count_start"
              placeholder="期数范围开始值"
              style="width: 80px"
              type="Number"
              :disabled="isEdit && !editSign"
            />
            <span class="ml_10 mr_10">~</span>
            <el-input
              v-model="item.stage_count_end"
              placeholder="期数范围结束值"
              style="width: 80px"
              type="Number"
              :disabled="isEdit && !editSign"
            />
            期
            <el-input
              v-model="item.platform_fee_rate"
              placeholder="管理费费率"
              style="width: 100px; margin-left: 40px"
              type="Number"
              :disabled="isEdit && !editSign"
            >
              <i
                slot="suffix"
                style="width: 50px; margin-right: 10px; color: black"
                >%</i
              >
            </el-input>
            <el-button
              style="margin-left: 10px"
              v-if="!isEdit && !editSign && index != 0"
              @click="delRate(index)"
              >删除</el-button
            >
            <el-button
              style="margin-left: 10px"
              v-if="isEdit && editSign && index != 0"
              @click="delRate(index)"
              >删除</el-button
            >
          </div>
        </el-form-item>
        <el-form-item v-if="!isEdit && !editSign">
          <el-button type="primary" @click="addRate">添加一项</el-button>
        </el-form-item>
        <el-form-item v-if="isEdit && editSign">
          <el-button type="primary" @click="addRate">添加一项</el-button>
        </el-form-item>
      </div>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="一级商家：">
            <el-select
              style="width: 100%; min-width: 180px"
              v-model="createForm.senior_id"
              :remote-method="seniorRemoteMethod"
              :loading="seniorLoading"
              placeholder="请输入关键词"
              @change="onSeniorChange"
              @clear="onSeniorClear"
              clearable
              filterable
              remote
              ref="cascader"
              @visible-change="(v) => visibleChange(v, 'cascader')"
              :disabled="isEdit && !editSign"
            >
              <el-option
                v-for="item in seniorList"
                :key="item.senior_id"
                :label="item.senior_name"
                :value="item.senior_id"
              >
                <span class="check" />
                <span style="margin-left: 8px">{{ item.senior_name }}</span>
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="关联商务：" prop="affiliated_user">
            <el-select
              style="width: 100%; min-width: 180px"
              v-model="createForm.affiliated_user"
              clearable
              :disabled="isEdit && !editSign"
            >
              <el-option
                v-for="item in affiliatedUserList"
                :key="item.manager_id"
                :label="item.username"
                :value="item.manager_id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="edit-button" v-if="entity_id && createForm.sign_status > 1">
        <el-button type="primary" v-if="!editSign" @click="editInfo('sign')"
          >编辑</el-button
        >
        <el-button type="primary" v-else @click="getEditEntity('sign')"
          >保存</el-button
        >
      </div>

      <el-divider content-position="left">
        <h2>分账类型</h2>
      </el-divider>
      <div>
        <el-form-item label="分账类型选择：">
            <el-radio
              v-model="createForm.ledger_type"
              :label="0"
              :disabled="!editLedger"
              >平台收款分账</el-radio
            >
            <el-radio
              v-model="createForm.ledger_type"
              :label="1"
              :disabled="!editLedger"
              >机构收款分账</el-radio
            >
          </el-form-item>
      </div>
      <div class="edit-button" v-if="entity_id && createForm.sign_status > 1">
        <el-button type="primary" v-if="!editLedger" @click="editInfo('ledger')"
          >编辑</el-button
        >
        <el-button type="primary" v-else @click="getEditEntity('ledger')"
          >保存</el-button
        >
      </div>
      <el-divider />

      <div class="submit-button">
        <el-button @click="goList()">返回</el-button>
        <el-button
          type="primary"
          v-if="!entity_id || createForm.sign_status <= 1"
          @click="onSubmit('createForm')"
          >确定</el-button
        >
      </div>
    </el-form>
    <el-dialog :visible.sync="showPreviewImg">
      <img width="100%" :src="previewImgUrl" />
    </el-dialog>
    <seniorModal ref="seniorModal" @getNewSeniorName="getNewSeniorName" />
  </div>
</template>

<script>
import {
  credentialsType,
  bankAccountType,
  contractType,
  personageBankAccountType,
} from "@/assets/equipment/allSelect.json";
// editEntity
import { infoEntity, createEntity, editEntity } from "@/api/entity.js";
import seniorModal from "../senior/components/senior-modal.vue";
import { getAuth } from "@/utils/index.js";
import {
  listBank,
  listProvince,
  listCity,
  listDistrict,
  selectSenior,
  selectListAccount,
} from "@/api/selectfun.js";
import uploadImage from "@/components/upload-image/index.vue";
export default {
  name: "createEntiy",
  components: { uploadImage, seniorModal },
  data() {
    var userIdnum = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入身份证号"));
      } else {
        var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
        if (reg.test(value) === false) {
          callback(new Error("身份证输入不合法"));
        } else {
          callback();
        }
      }
    };
    var itemRules = (rule, value, callback) => {
      value.map((item) => {
        if (
          item.stage_count_start === "" ||
          item.stage_count_end === "" ||
          item.platform_fee_rate === ""
        ) {
          callback(new Error("请输入平台管理费费率"));
        } else {
          if (item.stage_count_start <= 0) {
            callback(new Error("开始期数不能低于1"));
          } else if (item.stage_count_end > 36) {
            callback(new Error("结束期数不能超过36"));
          } else if (item.platform_fee_rate > 1.5) {
            callback(new Error("平台管理费率不能超过1.5"));
          } else {
            callback();
          }
        }
      });
    };
    return {
      managerType: localStorage.getItem("managerType"),
      getAuth: getAuth,
      showPreviewImg: false,
      previewImgUrl: "",
      entity_id: "",
      createForm: {
        sign_type: "ENTERPRISE",
        bill_type: 1, // 目前只有一种
        contract_type: 0,
        is_auto_withdraw: 1,
        entity_fee_list: [
          {
            stage_count_start: "",
            stage_count_end: "",
            platform_fee_rate: "",
          },
        ],
      },
      personageAccountTypeList: personageBankAccountType,
      signTypeList: contractType,
      affiliatedUserList: [],
      provinceList: [],
      cityList: [],
      countyList: [],
      seniorList: [],
      accountTypeList: bankAccountType,
      bankList: [],
      credentialsTypeList: credentialsType,
      rules: {
        entity_fee_list: [{ validator: itemRules, trigger: "blur" }],
        contact_user_idnum: [{ validator: userIdnum, trigger: "blur" }],
        affiliated_user: [
          { required: true, message: "请选择关联商务", trigger: "change" },
        ],
        service_phone: [
          { required: true, message: "请输入客服电话", trigger: "blur" },
        ],
        business_address_district_id: [
          { required: true, message: "请选择区县", trigger: "change" },
        ],
        business_address_city_id: [
          { required: true, message: "请选择市", trigger: "change" },
        ],
        business_address_province_id: [
          { required: true, message: "请选择省", trigger: "change" },
        ],
        entity_account_lience_num: [
          { required: true, message: "请输入开户许可证编号", trigger: "blur" },
        ],
        business_address: [
          { required: true, message: "请输入公司经营地址", trigger: "blur" },
        ],
        legal_user_licence_type: [
          { required: true, message: "请选择法人证件类型", trigger: "change" },
        ],
        legal_user_licence_no: [
          { required: true, message: "请输入法人证件号码", trigger: "blur" },
        ],
        legal_user_name: [
          { required: true, message: "请输入法人姓名", trigger: "blur" },
        ],
        entity_simple_name: [
          { required: true, message: "请输入公司简称", trigger: "blur" },
        ],
        product: [
          { required: true, message: "请选择所属产品", trigger: "change" },
        ],
        contract_type: [
          { required: true, message: "请选择合同类型", trigger: "change" },
        ],
        entity_name: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
        ],
        entity_number: [
          {
            required: true,
            message: "请输入统一社会信用代码",
            trigger: "blur",
          },
        ],
        contact_user_name: [
          { required: true, message: "请输入联系人姓名", trigger: "blur" },
        ],
        contact_user_email: [
          { required: true, message: "请输入联系人邮箱", trigger: "blur" },
        ],
        contact_user_phone: [
          { required: true, message: "请输入联系人电话", trigger: "blur" },
        ],
        entity_account_type: [
          { required: true, message: "请选择账户类型", trigger: "change" },
        ],
        entity_account: [
          { required: true, message: "请输入账号", trigger: "blur" },
        ],
        bank_id: [
          { required: true, message: "请选择开户行", trigger: "change" },
        ],
      },
      listBankSearch: {
        per_page: 10,
        bank_name: "",
      },
      seniorNameSearch: {
        senior_name: "",
      },
      seniorLoading: false,
      isEdit: false,
      imgflg: false,
      editBack: false,
      editSign: false,
      editLedger: false
    };
  },

  mounted() {
    this.getListBank();
    this.getListProvince();
    this.getSelectListAccount();
    this.entity_id = this.$route.query.entity_id;
    if (this.entity_id) {
      this.initData();
    }
  },

  methods: {
    visibleChange(visible, refName) {
      if (visible) {
        const ref = this.$refs[refName];
        let popper = ref.$refs.popper;
        if (popper.$el) popper = popper.$el;
        if (
          !Array.from(popper.children).some(
            (v) => v.className === "el-cascader-menu__list"
          )
        ) {
          const el = document.createElement("ul");
          el.className = "el-cascader-menu__list";
          el.style =
            "border-top: solid 1px #E4E7ED; padding:0;color: #4f7bff;cursor: pointer;";
          el.innerHTML = `<li class="el-cascader-node" style="height:38px;line-height: 38px">
                          <span class="el-cascader-node__label">+新增一级机构</span>
                          </li>`;
          popper.appendChild(el);
          el.onclick = () => {
            if (this.managerType != 0 || getAuth("seniorOperate")) {
              this.$refs.seniorModal.isShow();
            } else {
              this.$message({
                message: "当前用户没有权限新增一级商家",
                type: "warning",
              });
            }
          };
        }
      }
    },
    getNewSeniorName(item) {
      this.seniorRemoteMethod(item.senior_name);
    },
    previewBigImg(src) {
      this.previewImgUrl = src;
      this.showPreviewImg = true;
    },
    stageCountEndInput(val) {
      if (val != "") {
        if (val > 36) {
          this.$message({
            message: "不能超过36期",
            type: "warning",
          });
        }
      }
    },
    addRate() {
      this.createForm.entity_fee_list.push({
        stage_count_start: "",
        stage_count_end: "",
        platform_fee_rate: "",
      });
    },
    delRate(index) {
      this.createForm.entity_fee_list.splice(index, 1);
    },
    editInfo(type) {
      if (type == "bank") {
        this.editBack = true;
      } else if (type == "sign") {
        this.editSign = true;
      } else if (type == "ledger") {
        this.editLedger = true
      }
    },
    async initData() {
      this.imgflg = false;
      const parmes = {
        entity_id: this.entity_id,
      };
      this.isEdit = false;
      const { code, data } = await infoEntity(parmes);
      if (code == 200) {
        this.imgflg = true;
        if (data.sign_status <= 1) {
          this.isEdit = false;
        } else {
          this.isEdit = true;
        }
        this.createForm = {
          ...data,
          affiliated_user: data.affiliated_user_id,
          bank_id: data.bank_id,
        };
        this.getListCity(data.business_address_province_id);
        this.getListDistrict(data.business_address_city_id);
        this.remoteMethod(data.bank_full_name);
        this.seniorRemoteMethod(data.senior_name);
      }
    },
    onSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.entity_id) {
            await this.getEditEntity("all");
          } else {
            const res = await createEntity(this.createForm);
            if (res.code == 200) {
              this.$message.success("操作成功");
              this.$router.push("/business/entityList");
            }
          }
        } else {
          return false;
        }
      });
    },

    async getEditEntity(type) {
      let param;
      if (type == "all") {
        param = {
          ...this.createForm,
          edit_type: "all",
        };
      } else if (type == "sign") {
        param = {
          entity_id: this.createForm.entity_id,
          senior_id: this.createForm.senior_id,
          affiliated_user: this.createForm.affiliated_user,
          entity_fee_list: this.createForm.entity_fee_list,
          edit_type: "sign",
        };
      } else if (type == "bank") {
        param = {
          entity_id: this.createForm.entity_id,
          bank_id: this.createForm.bank_id,
          entity_account: this.createForm.entity_account,
          entity_account_type: this.createForm.entity_account_type,
          edit_type: "bank",
        };
      } else if (type == "ledger") {
        param = {
          entity_id: this.createForm.entity_id,
          ledger_type: this.createForm.ledger_type,
          edit_type: "ledger",
        }
      }
      console.log(param);
      const res = await editEntity(param);
      if (res.code == 200) {
        this.$message.success("操作成功");
        this.$router.push("/business/entityList");
      }
    },

    remoteMethod(query) {
      this.listBankSearch.bank_name = query;
      this.getListBank();
    },

    async getListBank() {
      const { code, data } = await listBank(this.listBankSearch);
      if (code == 200) {
        this.bankList = data.map((item) => {
          item.value = item.id;
          item.label = item.full_name;
          return item;
        });
      }
    },
    async getListProvince() {
      const { code, data } = await listProvince();
      if (code == 200) {
        this.provinceList = data.map((item) => {
          item.value = item.id;
          item.label = item.name;
          return item;
        });
      }
    },
    onProvinceChange(e) {
      delete this.createForm.business_address_city_id;
      this.$set(this.createForm, "business_address_city_id", []);
      delete this.createForm.business_address_district_id;
      this.$set(this.createForm, "business_address_district_id", []);
      this.$nextTick(() => {
        this.$refs["createForm"].clearValidate("business_address_city_id");
        this.$refs["createForm"].clearValidate("business_address_district_id");
      });
      this.getListCity(e);
    },
    async getListCity(e) {
      const parme = {
        province_id: e,
      };
      const { code, data } = await listCity(parme);
      if (code == 200) {
        this.cityList = data.map((item) => {
          item.value = item.id;
          item.label = item.name;
          return item;
        });
      }
    },
    onCityChange(e) {
      delete this.createForm.business_address_district_id;
      this.$set(this.createForm, "business_address_district_id", []);
      this.$nextTick(() => {
        this.$refs["createForm"].clearValidate("business_address_district_id");
      });
      this.getListDistrict(e);
    },
    async getListDistrict(e) {
      const parme = {
        city_id: e,
      };
      const { code, data } = await listDistrict(parme);
      if (code == 200) {
        this.countyList = data.map((item) => {
          item.value = item.id;
          item.label = item.name;
          return item;
        });
      }
    },
    onSeniorChange(val) {
      this.createForm.senior_id = val;
    },
    onSeniorClear() {
      this.createForm.senior_id = "";
      this.seniorList = [];
    },
    seniorRemoteMethod(query) {
      if (query != "") {
        this.seniorLoading = true;
        setTimeout(() => {
          this.seniorLoading = false;
          this.seniorNameSearch.senior_name = query;
          this.getSelectSenior();
        }, 200);
      } else {
        this.seniorList = [];
      }
    },
    async getSelectSenior() {
      const { code, data } = await selectSenior(this.seniorNameSearch);
      if (code == 200) {
        this.seniorList = data;
      }
    },
    getLicencePhotoPath(val) {
      this.createForm.licence_photo_path = val;
    },
    // 法人身份证正面
    getLegalUserLicencePhotoFront(val) {
      this.createForm.legal_user_licence_photo_front = val;
    },
    // 法人身份证反面
    getLegalUserLicencePhotoBack(val) {
      this.createForm.legal_user_licence_photo_back = val;
    },
    // 银行开户许可证
    getEntityAccountLiencePhoto(val) {
      this.createForm.entity_account_lience_photo = val;
    },
    async getSelectListAccount() {
      const { code, data } = await selectListAccount();
      if (code == 200) {
        this.affiliatedUserList = data;
      }
    },
    goList() {
      this.$router.push("/business/entityList");
      this.$refs["createForm"].resetFields();
    },
  },
};
</script>

<style lang="scss" scoped>
.createEntiy {
  .edit-button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .upload_img {
    width: 98px;
    height: 98px;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid #c0ccda;
  }
  .submit-button {
    display: flex;
    justify-content: center;
    .el-button {
      width: 200px;
      height: 50px;
    }
  }
  .rate-list {
    margin: 5px 0px;
  }
  /deep/ .el-upload {
    width: 100px;
    height: 100px;
    line-height: 106px;
  }
  /deep/ .is-uploading {
    width: 100px;
    height: 100px;
  }
  /deep/ .el-upload-list__item {
    width: 100px;
    height: 100px;
  }
  /deep/ .el-progress {
    width: 86px;
  }
  /deep/ .el-progress-circle {
    width: 86px;
    height: 86px;
  }
}
</style>