<template>
  <div class="uploadImage">
    <el-upload
      action=""
      list-type="picture-card"
      :file-list="fileList"
      :http-request="fnUploadRequest"
      :limit="limit"
      :on-preview="handlePreview"
      :on-exceed="handExceed"
      :before-upload="handleUpload"
      :before-remove="handleRemove"
      :class="{
        styleA: fileList.length === 0,
        styleB: fileList.length === 1,
      }"
    >
      <i class="el-icon-plus"></i>
    </el-upload>
    <el-dialog width="30%" :visible.sync="previewVisible">
      <img width="100%" :src="previewImage" alt />
    </el-dialog>
  </div>
</template>

<script>
import { getOSSConf } from "@/api/util.js";
import axios from "@/utils/request";
// import config from "@/utils/config";
export default {
  props: {
    limit: Number,
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      previewVisible: false,
      previewImage: "",
      fileList: [],
      imgUrl: "",
      successList: [],
    };
  },
  watch: {
    value(newnew) {
      if (newnew) {
        this.fileList = [];
        this.fileList.push({
          url: this.value,
        });
      }
      this.imgUrl = this.value;
    },
  },
  mounted() {
    this.fileList = [];
    if (this.value) {
      this.fileList.push({
        url: this.value,
      });
    }
  },
  methods: {
    handleUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension =
        testmsg === "jpg" ||
        testmsg === "png" ||
        testmsg === "JPG" ||
        testmsg === "PNG";
      var bool = false;
      if (extension) {
        bool = true;
      } else {
        bool = false;
      }
      if (!extension) {
        this.$message.error("请选择图片类型！");
        return bool;
      }
      return bool;
    },
    async fnUploadRequest(options) {
      const { data } = await getOSSConf();
      var extension = "";
      if (/.*(\.[^\\.]*)/.test(options.file.name)) {
        extension = RegExp.$1;
      }
      const filename = data.filename;
      const osspath = data.policy.dir + filename + extension;

      let formData = new FormData();
      formData.append("key", osspath);
      formData.append("OSSAccessKeyId", data.policy.accessid);
      formData.append("policy", data.policy.policy);
      formData.append("signature", data.policy.signature);
      formData.append("success_action_status", 200);
      formData.append("file", options.file);

      axios({
        url: data.policy.host,
        method: "post",
        processData: false,
        data: formData,
      })
        .then(() => {
          this.imgUrl = osspath;
          this.fileList.push({
            url: data.domain +'/'+osspath,
          });
          this.$emit("update:value", osspath);
          this.$emit("getImgUrl", osspath);
        })
        .catch(() => {});
    },
    async handlePreview(file) {
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleRemove() {
      this.fileList = [];
    },
    handExceed() {
      this.$message.warning("限制上传数量1个文件以内！");
    },
  },
};
</script>

<style  scope>
.styleA .el-upload--picture-card {
  width: 110px;
  height: 110px;
  line-height: 110px;
}
.styleB .el-upload--picture-card {
  display: none;
}
</style>